
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { FormRulesMixin, VuetifyMixin, AutofocusMixin, AvailableRoles, SportsSelectorMixin } from '../../mixins';

import { OnboardingUserInfoFormValue } from '@/../types/interfaces';

@Component
export default class OnboardingUserInfo extends Mixins(AutofocusMixin, FormRulesMixin, AvailableRoles, SportsSelectorMixin, VuetifyMixin){
	@Prop({ default: () => ({valid: false}) }) value: OnboardingUserInfoFormValue;
	@Prop({ type: Boolean }) selectSport;
	@Prop({ type: Boolean }) isParent;

	input(): void{
		this.$emit('input', this.value);
	}
	submit(): void{
		this.$emit('submit', this.value);
	}

	get FirstNameLabel(): string{
		if(this.isParent){
			return "Your Child's First Name";
		}
		return "First Name";
	}
	get LastNameLabel(): string{
		if(this.isParent){
			return "Your Child's Last Name";
		}
		return "Last Name";
	}
	get EmailLabel(): string{
		if(this.isParent){
			return "Optional: Your Child's Email Address";
		}
		return "Email Address";
	}
	get SportLabel(): string{
		if(this.isParent){
			return "What sport does your child play?";
		}
		return "What sport do you play?";
	}
}
