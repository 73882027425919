
import { Component, Mixins, Prop } from 'vue-property-decorator';
import RoleCard from '@/components/onboarding/RoleCard.vue';
import { FormRulesMixin, VuetifyMixin, AutofocusMixin, AvailableRoles, SportsSelectorMixin } from '../../mixins';

import { OnboardingClaimAthleteProfileFormValue } from '@/../types/interfaces/OnboardingClaimAthleteProfileFormValue';
import { AthleteProfileModel } from '@/models';

@Component({
	components: { RoleCard },
})
export default class OnboardingClaimAthleteProfile extends Mixins(AutofocusMixin, FormRulesMixin, AvailableRoles, SportsSelectorMixin, VuetifyMixin){

	get Athletes(): AthleteProfileModel[]{
		return this.profiles;
	}

	@Prop({ default: () => [] }) profiles: AthleteProfileModel[];
	@Prop({ default: () => ({
		valid: true,
		athlete: null,
		profiles: [],
	}) }) value: OnboardingClaimAthleteProfileFormValue<AthleteProfileModel>;

	input(): void{
		this.$emit('input', this.value);
	}
	submit(): void{
		this.$emit('submit', this.value);
	}
	selectAthlete(athlete: AthleteProfileModel): void{
		this.value.athlete = athlete;
		this.input();
		this.submit();
	}
	notYouSelected(): void{
		this.value = {
			...this.value,
			athlete: null,
		};
		this.input();
		this.submit();
	}

	mounted(): void{
		// No profiles found, move onto next step
		if(this.profiles.length === 0){
			this.submit();
		}
	}


}
