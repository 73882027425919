
import { Component, Mixins } from 'vue-property-decorator';
import { AuthMixin, MultiStepFormMixin, VuetifyMixin, DebounceMixin,BAIconsMixin } from '../../mixins';
import { notificationStore, userStore } from '../../store';

import Page from '../Page.vue';
import MultiStepForm from '../../components/forms/MultiStepForm.vue';
import OnboardingUserRole from '../../components/onboarding/OnboardingUserRole.vue';
import UserSelfReportSvg from '../../components/svg/UserSelfReportSvg.vue';
import { userApi } from '@/api/UserApi';

@Component({
	components: {
		Page,
		MultiStepForm,
		OnboardingUserRole,
		UserSelfReportSvg,
	},
})
export default class EmailVerification extends Mixins(AuthMixin, DebounceMixin, MultiStepFormMixin, VuetifyMixin, BAIconsMixin) {

	loading: boolean = false;
	steps: number = 1;
	currentStep = 1;

	get EmailVerified(): boolean{
		return userStore.EmailVerified;
	}

	get Progress(): number{
		return 10;
	}

	get TotalSteps(): number{
		return 1;
	}

	get FormTitleText(): string{
		return "Please verify your email address to continue";
	}

	refresh(): void{
		window.location.reload();
	}
	cancel(): void{
		this.$auth.logout();
	}

	mounted(): void{
		if(this.EmailVerified === true){
			this.$router.push({
				path: '/',
				query: { ...this.$route.query },
				params: { ...this.$route.params },
			});
		}
	}

	resentEmail: boolean = false;
	resentEmailLoading: boolean = false;
	resetVerificationEmail(): void{
		this.resentEmail = false;
	}
	async resendEmail(): Promise<void>{
		if (!this.resentEmail){
			try {
				this.resentEmailLoading = true;
				await userApi.resendVerificationEmail()
				this.resentEmail = true;
				notificationStore.pushSnackbarSuccess({message: "Verification email sent"});
				this.debounceCallback('resetVerificationEmail', () => this.resetVerificationEmail(), 4000);
			} catch (error) {
				notificationStore.pushErrorNotificationMessage("Unable to send verification email. Please try again later");
			}finally{
				this.resentEmailLoading = false;
			}
		}
	}

}
