
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { AutofocusMixin, FormRulesMixin, VuetifyMixin, CountriesSelectorMixin, AuthMixin } from '../../../mixins';

import { AthleteOnboardingFormValue } from '@/../types/interfaces';
import TOSCheckbox from '../../forms/TOSCheckbox.vue';

@Component({
	components: { TOSCheckbox }
})
export default class OnboardingAthleteLocation extends Mixins(AuthMixin, AutofocusMixin, CountriesSelectorMixin, FormRulesMixin, VuetifyMixin){
	@Prop({ default: (): AthleteOnboardingFormValue['step3'] => ({
		valid: false,
		country: "",
		city: "",
		fromOrganization: "",
		tosAgreed: false,
	}) }) value: AthleteOnboardingFormValue['step3'];

	@Prop({ type: Boolean, default: false }) isParent: boolean;

	get TOSAccepted(): boolean{
		if(this.$auth.user.user_metadata === undefined) return false;
		if(this.$auth.user.user_metadata.tosAccepted) return true;
		return false;
	}

	get GradYearLabel(): string{
		if(this.isParent){
			return "Optional - What year is your child graduating?";
		}
		return "Optional - What year are you graduating?";
	}

	input(): void{
		this.$emit('input', this.value);
	}
	submit(): void{
		this.$emit('submit', this.value);
	}

	get AvailableGradYears(): number[]{
		const thisYear = new Date().getUTCFullYear();
		return new Array(10).fill(thisYear).map((y, i) => y+i);
	}
}
